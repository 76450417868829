import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "./BaseModal";

export interface SpecialistToBeAddedModalProps {
    close: () => void;
    message: string;
}

export default function SpecialistToBeAddedModal(props: SpecialistToBeAddedModalProps) {
    const { t } = useTranslation();
    return (
        <BaseModal close={props.close} headerKey={"general.information"} headerImage={"/static/images/infotrans.png"}>
            <div className="advice__body--text">{t(props.message)}</div>
            <div className="advice__buttons">
                <a href="/search/spec">
                    <div className="advice__order">{t("newConferenceProcess.createConsultation")}</div>
                </a>
                <a href="/search/spec/concilium">
                    <div className="advice__find">{t("newConferenceProcess.createConcilium")}</div>
                </a>
            </div>
        </BaseModal>
    );
}
