import moment from "moment";
import { PICKER_DATETIME_FORMAT } from "../domain/constants/Constants";

export function getTimeStr(time: moment.Moment): string {
    if (!time) {
        return "";
    }
    return `${time.date() < 10 ? "0" + time.date() : time.date()}.${
        time.month() + 1 < 10 ? "0" + (time.month() + 1) : time.month() + 1
    }.${time.year()}`;
}

export function getPickerCurrentTime(): string {
    return moment().format(PICKER_DATETIME_FORMAT);
}
