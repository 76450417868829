import ImageUploading, { ImageListType } from "react-images-uploading";
import React, { FC, useState } from "react";
import { ParentType } from "../../enums/parent-type";
import { FileModel } from "../../models/FileModel";
import _ from "lodash";

interface AddImageButtonProps {
    t(key?: string): string;
    parentType: ParentType;
    children: any;
    className: string;
    addImage(image: any): void;
}

const AddImageButton: FC<AddImageButtonProps> = (props) => {
    const [images, setImage] = useState<ImageListType>([]);
    const onChange = (newImages: ImageListType) => {
        const image = newImages[0];
        if (!image.file) {
            return;
        }
        const file = new FileModel({
            dataUrl: image.dataURL,
            parentType: props.parentType,
            fileType: _.get(image, "file.type", "").split("/")[1],
        });
        setImage(newImages);
        props.addImage(file);
    };

    return (
        <ImageUploading value={images} onChange={onChange} multiple={false}>
            {({ onImageUpload, dragProps }) => (
                <div onClick={onImageUpload} {...dragProps} className={props.className}>
                    {..._.isArray(props.children) ? props.children : [props.children]}
                </div>
            )}
        </ImageUploading>
    );
};

export default AddImageButton;
