import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import { Subscription } from "rxjs";
import { userServiceFactory } from "../../concilium/services/UserServiceImpl";
import { CartStatus } from "../../domain/enums/CartStatus";
import { Errors } from "../../domain/enums/Errors";
import { UserService } from "../../domain/services/UserService";
import { CartToolsService } from "../../services/CartToolsService";
import { ErrorServiceFactory } from "../../services/ErrorService";
import ChangeSpecialistModal from "../modal/ChangeSpecialistModal";
import SpecialistToBeAddedModal from "../modal/SpecialistToBeAddedModal";
import { NewConferenceContext } from "./NewConferenceContext";
import MaximumOfSpecialistAddedModal from "../modal/MaximumOfSpecialistAddedModal";

interface NewConferenceProcessButtonProperty {
    t(key?: string): string;
}

interface NewConferenceProcessButtonState {
    openContext: boolean;
    addSpecialistError?: Errors;
}

class NewConferenceProcessButton extends Component<
    NewConferenceProcessButtonProperty,
    NewConferenceProcessButtonState
> {
    subscriptions: Subscription[] = [];
    errorService = ErrorServiceFactory();
    userService: UserService;
    initialStatus: CartStatus;

    constructor(props) {
        super(props);
        this.userService = userServiceFactory();
        this.state = {
            openContext: false,
        };
    }

    handleOpenContextChange = () => {
        this.setState({
            openContext: false,
        });
    };

    async componentDidMount(): Promise<void> {
        CartToolsService.addToCartErrorSubject.subscribe((addSpecialistError) => {
            this.setAddSpecialistError(addSpecialistError);
        });
        await this.userService.getCachedUser();
    }

    componentWillUnmount(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    setAddSpecialistError(error?: Errors) {
        this.setState({ addSpecialistError: error });
    }

    setOpenContext(value?: boolean, handler?: any) {
        this.setState({ openContext: value }, handler);
    }

    setInitialStatus = (status?: CartStatus) => {
        this.initialStatus = status;
    };

    showSpecialistAddIssuePopup(close: () => void) {
        const t = this.props.t;
        const error = this.state.addSpecialistError;
        if (!error) {
            return;
        }
        const message = t(this.errorService.getErrorTranslateKeyByErrorCode(error, "errors."));
        switch (error) {
            case Errors.ADD_MED_SPECIALIST_FIRST:
            case Errors.NEED_MORE_MED_SPECIALISTS:
            case Errors.SPECIALIST_ALREADY_ADDED:
            case Errors.USER_SPECIALIST_DOES_NOT_EXISTS:
            case Errors.CAN_NOT_ADD_SPECIALIST:
            case Errors.ERROR_ADDING_SPECIALIST:
                return (
                    <SpecialistToBeAddedModal
                        close={() => {
                            this.setAddSpecialistError();
                            close();
                        }}
                        message={message}
                    ></SpecialistToBeAddedModal>
                );
            case Errors.MAX_PARTICIPANTS_NUMBER_REACHED:
                return (
                    <MaximumOfSpecialistAddedModal
                        close={() => {
                            this.setAddSpecialistError();
                            close();
                        }}
                        editButtonHandler={() => {
                            this.setOpenContext(true);
                        }}
                        message={message}
                    ></MaximumOfSpecialistAddedModal>
                );
            case Errors.TRANSLATOR_ALREADY_ADDED:
                // TODO :: after redirected need to open concilium modal
                return (
                    <ChangeSpecialistModal
                        close={() => {
                            this.setAddSpecialistError();
                            close();
                        }}
                        message={message}
                        actionURL={"/search/lang?edit=on"}
                        headerImage={"/static/images/mm1.png"}
                        buttonLabel={"specialistSearch.selectAnotherTranslator"}
                        headerMessage={"specialistSearch.translatorAlreadyAdded"}
                    ></ChangeSpecialistModal>
                );
            case Errors.ADMINISTRATOR_ALREADY_ADDED:
                // TODO :: after redirected need to open concilium modal
                return (
                    <ChangeSpecialistModal
                        close={() => {
                            this.setAddSpecialistError();
                            close();
                        }}
                        message={message}
                        actionURL={"/search/admin?edit=on"}
                        headerImage={"/static/images/a3.png"}
                        buttonLabel={"specialistSearch.selectAnotherAdministrator"}
                        headerMessage={"specialistSearch.administratorAlreadyAdded"}
                    ></ChangeSpecialistModal>
                );
            default:
                return (
                    <SpecialistToBeAddedModal
                        close={() => {
                            this.setAddSpecialistError();
                            close();
                        }}
                        message={t("errors.standardError")}
                    ></SpecialistToBeAddedModal>
                );
        }
    }

    closePopup = (close?) => {
        this.setInitialStatus();
        this.setOpenContext();
        if (close) close();
    };

    render() {
        // console.log(`NewConferenceProcessButton.render => initialStatus: ${this.initialStatus}`);
        return (
            <div>
                <div
                    id="conference-button"
                    className="header__action"
                    onClick={() => {
                        this.setOpenContext(true);
                    }}
                >
                    <img className="header__action--image" src="/static/images/camera.svg" />
                </div>

                <Popup
                    onClose={this.closePopup.bind(this)}
                    open={this.state.openContext}
                    modal
                    overlayStyle={{ zIndex: 999999 }}
                >
                    {() => (
                        <NewConferenceContext
                            t={this.props.t}
                            initialStatus={this.initialStatus}
                            close={() => {}}
                            handleOpenContextChange={this.handleOpenContextChange}
                        />
                    )}
                </Popup>

                <Popup open={!!this.state.addSpecialistError} modal overlayStyle={{ zIndex: 999999 }}>
                    {(close) => this.showSpecialistAddIssuePopup(close)}
                </Popup>
            </div>
        );
    }
}

export default withTranslation("translation", { withRef: true })(NewConferenceProcessButton);
