import React from "react";
import { CartStatus } from "../../domain/enums/CartStatus";
import { CustomFile } from "../../domain/models/CustomFile";
import { ParentType } from "../../enums/parent-type";
import AddImageButton from "../shared/AddImageButton";
import DateTimeView from "./DateTimeView";
import { NewConferencePayment } from "./NewConferencePayment";
import { NewConferenceSpelialistsSelectDateTime } from "./NewConferenceSpelialistsSelectDateTime";
import { NewConferenceState } from "./NewConferenceState";
import SpecialistsList from "./SpecialistsList";

// TODO :: pass appropriate properties
export class NewConferenceDetailsView extends NewConferenceState {
    file: CustomFile;
    async goToNextStep(): Promise<void> {
        await this.cartService.saveAndUpdate(true);
        await this.cartService.addFileToConcilium(this.file);
        this.props.context.transitionTo(NewConferencePayment);
    }

    goToPrevStep(): void {
        this.props.context.transitionTo(NewConferenceSpelialistsSelectDateTime);
    }

    cancel(): void {
        this.props.context.handlerCancel();
    }

    componentDidMount = async () => {
        await this.cartService.updateCurrentCartStatus(CartStatus.TIME_IS_SET);
    };

    addImage = async (file: CustomFile) => {
        file.parentId = this.cartService.cart.id;
        this.file = file;
    };

    render() {
        const cart = this.cartService.cart;
        return (
            <>
                <div id="create-concilium-container"></div>
                <div className="content content_margin-top">
                    <div className="modal active" style={{ overflow: "hidden" }}>
                        <div className="create-concilium-form">
                            <div
                                className="modal__body_ns modal__body_ns--width"
                                style={{ backgroundColor: "transparent" }}
                            >
                                <button
                                    type="submit"
                                    name="submit"
                                    value="cancel"
                                    className="modal__step modal__step_prev screen_buttons form-action-button"
                                    data-modal="mfirst"
                                >
                                    <div className="modal__step--count_ns">
                                        {this.props.t("newConferenceProcess.details.step_1")}
                                    </div>
                                    <div className="modal__step--info">
                                        <div className="modal__step--name_ns">
                                            {this.props.t("newConferenceProcess.details.basic")}
                                        </div>
                                        <img
                                            className="modal__step--arrow_ns"
                                            src="/static/images/arrowg.png"
                                            onClick={() => this.goToPrevStep()}
                                        />
                                    </div>
                                </button>
                                <button
                                    type="submit"
                                    name="submit"
                                    value="submit"
                                    className="modal__step modal__step_next screen_buttons form-action-button"
                                    data-modal="mthird"
                                >
                                    <div className="modal__step--count_ns">
                                        {this.props.t("newConferenceProcess.details.step_3")}
                                    </div>
                                    <div className="modal__step--info">
                                        <div className="modal__step--name_ns">
                                            {this.props.t("newConferenceProcess.details.payment")}
                                        </div>
                                        <img
                                            className="modal__step--arrow_ns"
                                            src="/static/images/arrowg.png"
                                            onClick={async () => {
                                                this.goToNextStep();
                                            }}
                                        />
                                    </div>
                                </button>
                                <div className="mfirst__top_ns mfirst__top_ns--pad modal__block__color-white">
                                    <div className="mfirst__line_ns">
                                        <div className="mfirst__column">
                                            <div className="mfirst__headline_ns">
                                                {this.props.t("newConferenceProcess.details.proposal")}
                                                {cart.isConcilium
                                                    ? this.props.t("newConferenceProcess.details.concilium")
                                                    : this.props.t("newConferenceProcess.details.consultation")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="msecond__list">
                                        <SpecialistsList t={this.props.t} />
                                    </div>
                                    <div className="msecond__form">
                                        <textarea
                                            className="msecond__input msecond__input_textarea"
                                            placeholder={this.props.t("newConferenceProcess.details.placeholder")}
                                            defaultValue={cart.description}
                                            onChange={(e) => cart.setDescription(e.target.value)}
                                        />
                                        <div className="msecond__form--inner">
                                            <AddImageButton
                                                t={this.props.t}
                                                addImage={this.addImage}
                                                className="msecond__file"
                                                parentType={ParentType.CONCILIUM}
                                            >
                                                <label className="msecond__file--label">
                                                    <img
                                                        className="msecond__file--icon"
                                                        src="/static/images/clip.png"
                                                    />
                                                    <div className="msecond__file--text">
                                                        {this.props.t("newConferenceProcess.details.addFile")}
                                                        {cart.fileName ? " (1)" : ""}
                                                    </div>
                                                </label>
                                            </AddImageButton>
                                            <div className="settings__check">
                                                <input
                                                    id="audio"
                                                    type="checkbox"
                                                    defaultChecked={cart.recordingAudio}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            cart.recordingAudio = e.target.checked;
                                                        }
                                                    }}
                                                />
                                                <label className="settings__check--label" htmlFor="audio">
                                                    <div className="settings__check--square_ns">
                                                        <img
                                                            className="settings__check--inner"
                                                            src="/static/images/greencheck.png"
                                                        />
                                                    </div>
                                                    <div className="settings__check--text_ns">
                                                        {this.props.t("newConferenceProcess.details.addAudio")}
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="settings__check">
                                                <input
                                                    id="video"
                                                    type="checkbox"
                                                    defaultChecked={cart.recordingVideo}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            cart.recordingVideo = e.target.checked;
                                                        }
                                                    }}
                                                />
                                                <label className="settings__check--label" htmlFor="video">
                                                    <div className="settings__check--square_ns">
                                                        <img
                                                            className="settings__check--inner"
                                                            src="/static/images/greencheck.png"
                                                        />
                                                    </div>
                                                    <div className="settings__check--text_ns">
                                                        {this.props.t("newConferenceProcess.details.addVideo")}
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mfirst__bottom_ns mfirst__bottom_ns--pad modal__block__color-grey"
                                    style={{ backgroundColor: "rgba(245, 243, 241) !important" }}
                                >
                                    <DateTimeView t={this.props.t} />
                                    <div className="mfirst__bottom_NextPrev mobile_buttons">
                                        <button
                                            type="submit"
                                            name="submit"
                                            value="cancel"
                                            className="modal__step modal__step_prev-mobile form-action-button"
                                            data-modal="mfirst"
                                        >
                                            <div className="modal__step--count_ns">
                                                {this.props.t("newConferenceProcess.details.step_1")}
                                            </div>
                                            <div className="modal__step--info">
                                                <div className="modal__step--name_ns">
                                                    {this.props.t("newConferenceProcess.details.basic")}
                                                </div>
                                                <img
                                                    className="modal__step--arrow_ns"
                                                    src="/static/images/arrowg.png"
                                                />
                                            </div>
                                        </button>
                                        <button
                                            type="submit"
                                            name="submit"
                                            value="submit"
                                            className="modal__step modal__step_next-mobile form-action-button"
                                            data-modal="mthird"
                                        >
                                            <div className="modal__step--count_ns">
                                                {this.props.t("newConferenceProcess.details.step_3")}
                                            </div>
                                            <div className="modal__step--info">
                                                <div className="modal__step--name_ns">
                                                    {this.props.t("newConferenceProcess.details.payment")}
                                                </div>
                                                <img
                                                    className="modal__step--arrow_ns"
                                                    src="/static/images/arrowg.png"
                                                    onClick={() => this.goToNextStep()}
                                                />
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
