import { Component } from "react";
import { CartService } from "../../domain/services/new-conference-process/CartService";
import { cartServiceFactory } from "../../services/CartServiceImpl";
import { NewConferenceContext } from "./NewConferenceContext";

interface NewConferenceStateProps {
    t(key?: string): string;
    context: NewConferenceContext;
}

interface NewConferenceStateState {
    showError?: boolean;
    dateTimeCurrentPage?: number;
    checkoutButtonActive?: boolean;
    error?: string;
}

export abstract class NewConferenceState extends Component<
    NewConferenceStateProps,
    NewConferenceStateState
> {
    context: NewConferenceContext;
    cartService: CartService;

    constructor(props) {
        super(props);
        this.cartService = cartServiceFactory();
    }

    goToNextStep(): void { };
    goToPrevStep(): void { };
    cancel(_close: () => void): void { };
}
