import React from "react";
import { NewConferenceDetailsView } from "./NewConferenceDetailsView";
import { NewConferenceSpelialistsSelectDateTime } from "./NewConferenceSpelialistsSelectDateTime";
import { NewConferenceState } from "./NewConferenceState";

export class NewConferenceExtra extends NewConferenceState {
    constructor(props) {
        super(props);
        this.state = {
            dateTimeCurrentPage: 0,
            showError: false,
        };
    }

    async goToNextStep(): Promise<void> {
        this.cartService.cart.setSkipExpress();
        await this.cartService.saveAndUpdate();
        this.props.context.transitionTo(NewConferenceDetailsView);
    }

    goToPrevStep(): void {
        this.props.context.transitionTo(NewConferenceSpelialistsSelectDateTime);
    }

    cancel(): void {
        this.props.context.handlerCancel();
    }

    render() {
        return (
            <div className="content content_margin-top">
                <div className="modal active">
                    <div className="modal__body_ns advice_ns">
                        <div className="advice__top_ns">
                            <img src="/static/images/minfo.png" alt="" />
                            <div className="advice__top--headline_ns">{this.props.t("general.information")}</div>
                        </div>
                        <div className="advice__body_ns">
                            <div className="advice__body--text_ns">
                                {this.props.t("newConferenceProcess.extra.text")}
                            </div>
                            <div className="advice__buttons_ns">
                                <a
                                    onClick={() => {
                                        this.goToNextStep();
                                    }}
                                >
                                    <div className="advice__button_ns">
                                        {this.props.t("newConferenceProcess.extra.next")}
                                    </div>
                                </a>
                                <a
                                    onClick={() => {
                                        this.goToPrevStep();
                                    }}
                                >
                                    <div className="advice__time_ns arrowNewApplic">
                                        <img src="/static/images/time.png" alt="" />
                                        {this.props.t("newConferenceProcess.extra.prev")}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
