import React, { Component } from "react";
import { userServiceFactory } from "../../concilium/services/UserServiceImpl";
import { CartStatus } from "../../domain/enums/CartStatus";
import { Cart } from "../../domain/models/Cart";
import { CartService } from "../../domain/services/new-conference-process/CartService";
import { cartServiceFactory } from "../../services/CartServiceImpl";
import NewConferenceSpelialistsList from "./NewConferenceSpelialistsList";

interface NewConferenceContextProps {
    initialStatus?: CartStatus;
    t(key?: string): string;
    close: () => void;
    handleOpenContextChange: () => void;
}

interface NewConferenceContextState {
    newConferenceState: any;
}

export class NewConferenceContext extends Component<NewConferenceContextProps, NewConferenceContextState> {
    cartService: CartService;

    constructor(props) {
        super(props);
        this.state = {
            newConferenceState: null,
        };
        this.cartService = cartServiceFactory();
        this.handleOpenContextChange = this.handleOpenContextChange.bind(this);
    }

    transitionTo(componentConstructor: any): void {
        this.setState({
            newConferenceState: React.createElement(componentConstructor, {
                t: this.props.t,
                context: this,
            }),
        });
    }

    handlerCancel(): void {
        this.setState(
            {
                newConferenceState: null,
            },
            () => {
                this.props.close();
                this.handleOpenContextChange();
            }
        );
    }

    handleOpenContextChange = () => {
        this.props.handleOpenContextChange();
    };

    componentDidMount = async () => {
        console.log("NewConferenceContext.componentDidMount");
        const cart = await this.cartService.getCachedCart(true);
        cart.setUser(await userServiceFactory().user);
        await this.cartService.uploadCartSchedule();
        const componentConstructor = NewConferenceContext.getNextStepComponentConstructor(
            cart,
            this.props.initialStatus
        );
        this.setState({
            newConferenceState: React.createElement(componentConstructor, {
                t: this.props.t,
                context: this,
            }),
        });
    };

    /**
     * TODO :: in case of increasing complexity need to consolidate
     * getNextStepComponentConstructor functionality to other place.
     * @param {Cart} cart - cart to process
     * @param {CartStatus} initialStatus - initial cart status which is used to get component to show.
     * @returns
     */
    static getNextStepComponentConstructor = (cart: Cart, initialStatus: CartStatus): any => {
        let componentConstructor = NewConferenceSpelialistsList;
        if (initialStatus === CartStatus.SPECIALISTS_ADDED) {
            componentConstructor = NewConferenceSpelialistsList.getNextStepComponentConstructor(cart);
        }
        return componentConstructor;
    };

    render = () => {
        return <>{this.cartService.cart && this.state.newConferenceState}</>;
    };
}
