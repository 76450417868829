import React, { Component } from "react";
import { CartService } from "../../domain/services/new-conference-process/CartService";
import { cartServiceFactory } from "../../services/CartServiceImpl";
import { getTimeStr } from "../../tools/DateTimeTools";
import TimeView from "./TimeView";

// TODO :: pass appropriate properties
export default class DateTimeView extends Component<{ t(key?: string): string }> {
    cartService: CartService;
    constructor(props) {
        super(props);
        this.cartService = cartServiceFactory();
    }

    render() {
        const time = this.cartService.cart.meetingTimeM;
        return (
            <>
                <div className="mfirst__headline_ns">
                    {this.cartService.cart.isConcilium
                        ? this.props.t("newConferenceProcess.dateTime.concilium")
                        : this.props.t("newConferenceProcess.dateTime.consultation")}
                </div>
                <div className="msecond__lines_ns">
                    <div className="msecond__line_ns">
                        <div className="msecond__line--info">
                            <img className="msecond__line--icon_ns" src="/static/images/calendary.png" />
                            <div className="msecond__line--date_ns">
                                {getTimeStr(time)}, {this.props.t("newConferenceProcess.dateTime.at")}{" "}
                                <TimeView time={time} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
