import React from "react";
import ReactDOM from "react-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translations } from "../../translations";
import cookie from "react-cookies";
import NewConferenceProcessButton from "./NewConferenceProcessButton";
import { CartToolsService } from "../../services/CartToolsService";

i18n.use(initReactI18next).init({
    resources: translations,
    lng: cookie.load("locale") || "ru",
});

const container = document.getElementById("new-conference-process");

const modalRef: React.RefObject<typeof NewConferenceProcessButton> =
    React.createRef<typeof NewConferenceProcessButton>();

if (container) {
    ReactDOM.render(<NewConferenceProcessButton ref={modalRef} />, container);
}

document.addEventListener("DOMContentLoaded", (_event) => {
    CartToolsService.initAddSpecialistButtons(modalRef);
    CartToolsService.initOpenCartModalHandler(modalRef);
    CartToolsService.initOpenCartModalNexStepHandler(modalRef);
});
