import { CustomFile } from "../domain/models/CustomFile";
import { FileDTO } from "../domain/models/dto/FileDTO";
import { ParentType } from "../enums/parent-type";

export class FileModel implements CustomFile {
    id?: string;
    parentType?: ParentType;
    fileType?: string;
    parentId?: string;
    filePath?: string;
    dataUrl?: string;
    isRemoved?: boolean = false;

    constructor(data: FileDTO) {
        Object.assign(this, data || {});
    }

    get isNewValid(): boolean {
        return !this.id && !!this.dataUrl && !this.isRemoved;
    }

    get needToBeRemoved(): boolean {
        return !!(this.id && this.isRemoved);
    }

    get isAbandoned(): boolean {
        return !this.id && !!this.isRemoved;
    }

    markAsRemoved() {
        this.isRemoved = true;
    }

    setParentId(parentId: string) {
        this.parentId = parentId;
    }
}
