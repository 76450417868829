import React from "react";

export interface FixedSimpleErrorProps {
    error?: string;
    className?: string;
}

export default function FixedSimpleError(props: FixedSimpleErrorProps) {
    return (
        <div className={`form-field-error ${props.className ? props.className : ""}`}>
            {props.error ? props.error : ""}
        </div>
    );
}
